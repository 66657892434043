export interface ImageProps {
  imageSrc: string;
  width?: number;
  height?: number;
  focus?: 'face' | 'custom' | 'auto';
}

export const getImageSrc = (props: ImageProps): string => {
  const { imageSrc, width, height, focus } = props;
  if (!imageSrc) return '';
  if (!width && !height && !focus) return imageSrc;
  let comma = '';
  let properties = 'tr:';
  const lastIndex = imageSrc.lastIndexOf('/');
  if (lastIndex == -1) return imageSrc;
  if (width) {
    properties += `w-${width}`;
    comma = ',';
  }
  if (height) {
    properties += `${comma}h-${height}`;
    comma = ',';
  }
  if (focus) {
    properties += `${comma}fo-${focus}`;
  }
  return `${imageSrc.substr(0, lastIndex)}/${properties}${imageSrc.substr(lastIndex)}`;
};
