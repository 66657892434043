import tw, { css, styled } from 'twin.macro';

const Heading1 = styled.h1`
  ${tw`text-headline-1 font-bold`};
`;
const Heading2 = styled.h2`
  ${tw`text-headline-2 font-bold`};
`;
const Heading3 = styled.h3`
  ${tw`text-headline-3 font-bold`};
`;
const Heading4 = styled.h4`
  ${tw`text-headline-4 font-bold`};
`;
const Heading5 = styled.h5`
  ${tw`text-headline-5 font-semibold`};
`;
const Heading6 = styled.h6`
  ${tw`text-headline-6 font-medium`};
`;

const Subtitle1 = styled.p`
  ${tw`text-subtitle-1 font-medium`};
`;
const Subtitle2 = styled.p`
  ${tw`text-subtitle-2 font-medium`};
`;
const Subtitle3 = styled.p`
  ${tw`text-caption font-medium`};
`;

const Body1 = styled.span`
  ${tw`text-body-1 font-normal`};
`;
const Body2 = styled.span`
  ${tw`text-body-2 font-normal`};
`;

const Caption = styled.p`
  ${tw`text-caption font-normal`};
`;

const Overline = styled.span`
  ${tw`text-overline font-semibold`};
  text-transform: uppercase;
`;

const ButtonText = css`
  ${tw`text-button font-bold`};
  text-transform: uppercase;
`;

const ErrorText = styled.span`
  ${tw`text-button font-semibold`};
`;

const Label = styled.label`
  ${tw`text-label font-medium`};
`;

export {
  ErrorText,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Subtitle1,
  Subtitle2,
  Subtitle3,
  Body1,
  Body2,
  Caption,
  Overline,
  ButtonText,
  Label,
};
