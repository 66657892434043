import * as MixpanelGeneralActions from '@analytics/mixpanel/actions/General';
import { SeoLink } from '@common/SeoLink';
import Routes from '@constants/routes';
import cookie from 'cookie';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { COOKIE_BUTTON, COOKIE_TEXT, I18N_COOKIE_CONTEXT } from './Cookie.constants';
import { CookieButton, CookieContainer, CookieLink, CookieText, CookieWrapper } from './Cookie.styles';

const Cookie = (): JSX.Element => {
  const router = useRouter();
  const cookieRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(I18N_COOKIE_CONTEXT);
  const [accepted, setAccepted] = useState<boolean>(true);

  useEffect(() => {
    const { cookieAcceptance } = cookie.parse(document.cookie);
    setAccepted(cookieAcceptance === `accepted`);
  }, []);

  const onAccepted = () => {
    MixpanelGeneralActions.onCookiesAccepted({ router });
    document.cookie = `cookieAcceptance= accepted; path=/; max-age=31536000`;
    setAccepted(true);
  };

  const onImproveLinkClicked = () => {
    MixpanelGeneralActions.onCookiesImproveLinkClicked({ router });
    router.push(Routes.COOKIE_POLICY);
  };

  return (
    <CookieWrapper accepted={accepted}>
      <CookieContainer ref={cookieRef}>
        <CookieText>
          <Trans i18nKey={`${I18N_COOKIE_CONTEXT}:${COOKIE_TEXT}`} components={[<CookieLink key='0' onClick={onImproveLinkClicked} />]} />
          <SeoLink href={Routes.COOKIE_POLICY} />
        </CookieText>
        <CookieButton onClick={onAccepted}>{t(COOKIE_BUTTON)}</CookieButton>
      </CookieContainer>
    </CookieWrapper>
  );
};
export default Cookie;
