import CanonicalURL from '@common/head/canonical-url';
import OpenGraph from '@common/head/open-graph';
import Cookie from '@features/common/Cookie/Cookie';
import { Container, Content, Page, StyledFooter, StyledHeader } from './BaseLayout.styles';
import { AppLayoutProps } from './BaseLayout.types';

const BaseLayout = ({ headerProps, showFooter, ...props }: AppLayoutProps): JSX.Element => {
  return (
    <Page>
      <OpenGraph infoOG={props.infoOG} />
      <CanonicalURL />
      {props.background}
      <Container shouldPushDown={!!props.aboveHeader}>
        {props.aboveHeader}
        <StyledHeader {...headerProps} />
        {props.aboveGrid}
        <Content>{props.children}</Content>
        {props.underGrid}
        {showFooter && <StyledFooter />}
      </Container>
      <Cookie />
    </Page>
  );
};

export default BaseLayout;
