import { getImageSrc } from '@common/image-helper';
import Head from 'next/head';
interface InfoOG {
  infoOG?: {
    title?: string;
    image?: string;
    ssrLocale: string;
    description?: string;
  };
}

export default function OpenGraph({ infoOG }: InfoOG): JSX.Element {
  return (
    <Head>
      <title>{infoOG?.title || 'Celebrity birthday wishes, personalized videos & shoutouts | Yela'}</title>
      <meta name='title' content={infoOG?.title || 'Celebrity birthday wishes, personalized videos & shoutouts | Yela'} />
      <meta
        name='description'
        content={
          infoOG?.description ||
          'Visit Yela and connect with your favorite Arabic celebrities. Send birthday wishes to friends, personalized videos, shoutouts, and much more. Give it a try today!'
        }
      />

      {/* Facebook Meta Tags */}
      <meta property='og:url' content='https://app.yela.com/' />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={infoOG?.title || 'Yela'} />
      <meta
        property='og:description'
        content={
          infoOG?.description ||
          (infoOG?.ssrLocale == 'ar' ? 'تواصل مع أهم المشاهير، حصريًا على موقعنا' : 'Meet the biggest celebrities, exclusively on Yela')
        }
      />
      <meta
        property='og:image'
        content={getImageSrc({
          imageSrc: infoOG?.image || 'https://ik.imagekit.io/yela/Production/Open_Graph/NEW_OG_Image_tqDyUAxx2G.png',
          height: 627,
          width: 1200,
        })}
      />

      {/* Twitter Meta Tags */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta property='twitter:domain' content='app.yela.com' />
      <meta property='twitter:url' content='https://app.yela.com/' />
      <meta name='twitter:title' content={infoOG?.title || 'Yela'} />
      <meta
        name='twitter:description'
        content={
          infoOG?.description ||
          (infoOG?.ssrLocale == 'ar' ? 'تواصل مع أهم المشاهير، حصريًا على موقعنا' : 'Meet the biggest celebrities, exclusively on Yela')
        }
      />
      <meta
        name='twitter:image'
        content={getImageSrc({
          imageSrc: infoOG?.image || 'https://ik.imagekit.io/yela/Production/Open_Graph/NEW_OG_Image_tqDyUAxx2G.png',
          height: 627,
          width: 1200,
        })}
      />
    </Head>
  );
}
