import dynamic from 'next/dynamic';
import tw, { styled } from 'twin.macro';

const Header = dynamic(() => import('@features/header/Header'));
const Footer = dynamic(() => import('@features/footer/Footer'));

export const Page = styled.div`
  ${tw`w-full h-full relative`};
`;

export const Container = styled.div<{ shouldPushDown: boolean }>`
  width: 100%;
  height: 100%;
  max-width: 1920px;
  min-height: 100vh;
  margin: 0 auto;
  ${tw`flex flex-col justify-between`}
  ${props => props.shouldPushDown && tw`sm:mt-6 md:mt-6 lg:mt-8 xl:mt-8`};
`;

export const StyledHeader = styled(Header)``;

export const Content = styled.div`
  ${tw`flex-1 grid`}
  ${tw`sm:grid-cols-4 sm:mx-2 sm:gap-x-2`}
  ${tw`md:grid-cols-8 md:mx-3 md:gap-x-2`}
  ${tw`lg:grid-cols-12 lg:mx-8 lg:gap-x-3`}
  ${tw`xl:grid-cols-12 xl:mx-8 xl:gap-x-3`}
`;

export const StyledFooter = styled(Footer)``;
