import { ButtonText, Subtitle2 } from '@components/Texts/Texts';
import tw, { css, styled, theme } from 'twin.macro';

export const CookieWrapper = styled.div<{ accepted: boolean }>`
  height: max-content;
  pointer-events: none;
  ${({ accepted }) => (accepted ? tw`hidden` : tw`flex`)};
  ${tw`fixed bottom-3 inset-x-0 w-full justify-center z-50`}
`;

export const CookieContainer = styled.div`
  max-width: 393px;
  pointer-events: all;
  background: #242429;
  box-shadow: 0px 48px 96px -8px rgba(75, 75, 77, 1);
  ${tw`flex items-center py-2 px-3 border-0 rounded-lg mx-auto`}

  ${css`
    @media (max-width: ${theme`screens.sm.max`}) {
      max-width: 90vw;
    }
  `}
`;

export const CookieText = styled(Subtitle2)`
  ${tw`text-light-high me-1`}
`;

export const CookieLink = styled.span`
  ${tw`cursor-pointer underline`}
`;

export const CookieButton = styled.a`
  ${ButtonText};
  padding-top: 11.5px;
  padding-bottom: 11.5px;
  ${tw`cursor-pointer h-6 flex items-center justify-center bg-brand-primary text-brand-background px-3 uppercase`}
`;
