import Head from 'next/head';
import { useRouter } from 'next/router';

const CanonicalURL = (): JSX.Element => {
  const router = useRouter();
  const _pathSliceLength = Math.min.apply(Math, [
    router.asPath.indexOf('?') > 0 ? router.asPath.indexOf('?') : router.asPath.length,
    router.asPath.indexOf('#') > 0 ? router.asPath.indexOf('#') : router.asPath.length,
  ]);
  const canonicalURL = process.env.NEXT_PUBLIC_SITE_URL + router.asPath.substring(0, _pathSliceLength);
  return (
    <Head>
      <link rel='canonical' href={canonicalURL} />
    </Head>
  );
};

export default CanonicalURL;
