export type SeoLinkProps = {
  href: string;
};

export const SeoLink = ({ href }: SeoLinkProps): JSX.Element => {
  return (
    <>
      <a href={`/en` + href} style={{ display: 'none' }} />
      <a href={`/ar` + href} style={{ display: 'none' }} />
    </>
  );
};
